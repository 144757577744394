<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Forgot Password v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <!-- logo -->
          <h2 class="brand-text text-primary ml-1">
            360 Clash
          </h2>
        </b-link>

        <b-card-title class="mb-1">
          Forgot Password?
        </b-card-title>
        <b-card-text class="mb-2">
          Enter your email and we'll send you instructions to reset your
          password
        </b-card-text>

        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form
            class="auth-forgot-password-form mt-2"
            @submit.prevent="resetPassword"
          >
            <!-- email -->
            <b-form-group
              label="Email"
              label-for="forgot-password-email"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="forgot-password-email"
                  v-model="userEmail"
                  :state="errors.length > 0 ? false : null"
                  name="forgot-password-email"
                  placeholder="john@example.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <spinning-button
              variant="primary"
              type="submit"
              block="block"
              :is-loading="isLoading"
            >
              Send reset link
            </spinning-button>
          </b-form>
        </validation-observer>

        <dismissible-message-box
          :variant="MessageType.ERROR"
          :show="showErrorNotifications"
        >
          <feather-icon
            icon="InfoIcon"
            class="mr-50"
          />
          {{ errors[0] }}
        </dismissible-message-box>

        <dismissible-message-box
          :variant="MessageType.SUCCESS"
          :show="showSuccessNotifications"
        >
          An email has been sent to the address you have provided. Please follow
          the link in the email to complete your password reset request.
        </dismissible-message-box>

        <b-card-text class="text-center mt-2">
          <b-link :to="{ name: 'login' }">
            <feather-icon icon="ChevronLeftIcon" />
            Back to login
          </b-link>
        </b-card-text>
      </b-card>
      <!-- /Forgot Password v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  BCard,
  BCardText,
  BCardTitle,
  BForm,
  BFormGroup,
  BFormInput,
  BLink,
} from 'bootstrap-vue'
import { email, required } from '@validations'
import DismissibleMessageBox from '@/components/DismissibleMessageBox.vue'
import MessageType from '@/constants/MessageType'
import SpinningButton from '@/components/SpinningButton.vue'

export default {
  components: {
    SpinningButton,
    DismissibleMessageBox,
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: '',
      required,
      email,
      errors: [],
      showErrorNotifications: false,
      showSuccessNotifications: false,
      isLoading: false,
    }
  },
  computed: {
    MessageType() {
      return MessageType
    },
  },
  methods: {
    async resetPassword() {
      this.errors = []
      this.showErrorNotifications = false
      this.showSuccessNotifications = false

      const formValid = await this.$refs.simpleRules.validate()
      if (!formValid) {
        return
      }

      this.isLoading = true
      const { success } = await this.$api.player.resetPassword(this.userEmail)
      this.isLoading = false

      if (!success) {
        this.errors = ['Something went wrong. Please try again later.']
        this.showErrorNotifications = true
        return
      }

      this.showSuccessNotifications = true
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/pages/page-auth.scss';
</style>
